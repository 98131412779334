import React, {useGlobal, useEffect} from 'reactn'
import {parseISO, formatDistance} from 'date-fns'
import makeStyles from '@material-ui/core/styles/makeStyles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import Section, {SectionHeading} from '../util/section'

const useStyles = makeStyles(theme => ({
    alertTitle: {
        display: 'flex',
        flexDirection: 'column'
    },
}));
const Advisories = () => {
    const [api] = useGlobal("api");
    const [advisories, setAdvisories] = useGlobal("advisories");
    const c = useStyles();

    useEffect(() => {
        if (api && setAdvisories) {
            fetch(`${api}/advisories`)
                .then(rsp => rsp.json())
                .then(data => setAdvisories(data["advisories"]))
                .catch(console.log)
        }
    }, [api, setAdvisories]);

    return (
        (advisories||[]).length > 0 ? <Section name="advisories">
            <SectionHeading title="Advisories" icon={<ErrorOutlineIcon/>}/>
            {advisories.map(a => <Box mb={1} key={a.id}><Alert  severity="warning" variant="outlined">
                <AlertTitle className={c.alertTitle}>
                    <Typography>{a.region}</Typography>
                    <Typography variant="caption">{formatDistance(new Date(),parseISO(a.date))} ago</Typography>
                </AlertTitle>
                {a.message}
            </Alert></Box>)}
        </Section> : null
    );
}

export default Advisories;
